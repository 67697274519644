<div class="main-content">
  <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
    <div class="container px-4">
      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
         aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
